import { useUrls } from '@/composables/useUrls';
import { useRoutes } from '~/composables/useRoutes.js';

export default defineNuxtRouteMiddleware(async (to) => {
  if (import.meta.server) {
    const nuxtApp = useNuxtApp();

    // If the error is already being handled, skip the validation.
    if (nuxtApp.payload.error) {
      return;
    }

    // ## COMPOSABLES
    const { useRouteManager } = useRoutes();
    const { useUrl } = useUrls();

    const urlParams = to?.params;
    const urlQuery = to?.query;

    // VALIDATE PROPERTY TYPE
    // If invalid property type, then default to 'hostels' property type (according to the user language);
    const propertyTypeObj = useRouteManager.validatePropertyTypeParam(urlParams.propertyType);

    // CHECK IF REQUESTED URL MATCHES THE USER LANGUAGE FORMAT
    if (propertyTypeObj.slug !== urlParams.propertyType.toLowerCase()) {
      // REQUESTED URL DOES NOT MATCH USER LANGUAGE FORMAT
      // REDIRECT USER TO THE APPROPRIATE URL FORMAT
      const worldPageUrlForCurrentLang = useUrl.getWorldPageUrl({ propertyTypeSlug: propertyTypeObj.slug }, urlQuery);
      const worldPagePathForCurrentLang = new URL(worldPageUrlForCurrentLang).pathname;
      navigateTo(worldPagePathForCurrentLang, {
        redirectCode: 301,
        external: false,
      });
    }
  }
});
